import { MaskInput } from "maska";
import JustValidate from 'just-validate';

document.addEventListener('DOMContentLoaded', function () {

    const body = document.body;
    const OS = navigator.platform;

    function showDialog(block) {
        block.show();
        document.body.style.overflow = "hidden";
        if (OS === "Win32" && window.innerWidth >= 1236) {
            body.style.paddingRight = "17px";
        }
    }

    function hideDialog(block) {
        block.close();
        document.body.style.overflow = "visible";
        body.style.paddingRight = "0px";
    }

    const burger = document.querySelector('.header__burger');
    const mobileMenu = document.querySelector('.header__menu_mobile');
    if (burger && mobileMenu) {
        burger.addEventListener('click', () => {
            burger.classList.toggle('is-active');
            mobileMenu.classList.toggle('is-active');
            if (burger.classList.contains('is-active')) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        })
    }

    //модальное окно
    const modalButtons = document.querySelectorAll('.modal-button');

    const modal = document.querySelector('.modal__position');
    const modalClose = document.querySelector('.modal__close');
    const modalDialogContent = document.querySelector('.modal-content');

    modalClose.addEventListener('click', function () {
        hideDialog(modal);
    });

    modalDialogContent.addEventListener('click', function (e) {
        console.log(e.target);
        const target = e.target;
        if (target.closest('.modal-content')) {
            return;
        }
        hideDialog(modal);
    });

    const modalOrder = document.querySelector('.modal-order__position');
    const modalOrderClose = document.querySelector('.modal-order__close');
    const modalOrderDialogContent = document.querySelector('.modal-order-content');

    modalButtons.forEach((button) => {
        button.addEventListener('click', () => {
            showDialog(modalOrder);
        })
    })

    modalOrderClose.addEventListener('click', function () {
        hideDialog(modalOrder);
    });

    modalOrderDialogContent.addEventListener('click', function (e) {
        console.log(e.target);
        const target = e.target;
        if (target.closest('.modal-order-content')) {
            return;
        }
        hideDialog(modalOrder);
    });

    //маска на телефоны
    const formPhones = document.querySelectorAll('.input-tel');

    formPhones.forEach(function (phone) {
        new MaskInput(phone);

        phone.addEventListener('input', function () {
            if (phone.value === '+7 (8') {
                phone.value = '+7';
            }
        });
    });

    //валидация форм
    const forms = document.querySelectorAll('.form-order');

    forms.forEach(function (form) {

        const validator = new JustValidate(form, {
            errorFieldCssClass: 'is-input-invalid',
            errorFieldStyle: {
                border: '1px solid #FF0000',
            },
            errorLabelCssClass: 'is-label-invalid',
            focusInvalidField: false,
            lockForm: true,
            submitFormAutomatically: false,
        });

        validator
            .addField('.input-tel',
                [
                    {
                        rule: 'required',
                        errorMessage: 'Вы не заполнили данное поле',
                    },
                    {
                        rule: 'minLength',
                        value: 18,
                        errorMessage: 'Введите корректный номер телефона',
                    },
                ],
            )

        form.addEventListener('submit', function (e) {
            console.log(form)
            console.log(form.checkValidity())
            if (form.checkValidity()) {
                e.preventDefault();
                createForm(form);
            }
        });

        function createForm(form) {

            const data = {
                'telephone': form.querySelector('.input-tel').value,
            };

            const responseBody = JSON.stringify(data);

            hideDialog(modalOrder);
            //ЗАГЛУШКА ПРОВЕРКИ ОТПРАВКИ
            document.querySelector('.form-modal__end_img').src = require('../images/icons/modal-succes.svg');
            document.querySelector('.modal__end_title').innerText = "Ваша заявка отправлена. Мы свяжемся с вами в самые кратчайшие сроки."
            showDialog(modal);

            // try {
            //     fetch('/api/', {
            //         method: 'POST',
            //         headers: {
            //             'Content-Type': 'application/json'
            //         },
            //         body: responseBody
            //     }).then(response => {
            //         return response.json();
            //     }).then(response => {
            //         document.querySelector('.form-modal__end_img').src = require('../images/icons/modal-succes.svg');
            //         document.querySelector('.modal__end_title').innerText = "Ваша заявка отправлена. Мы свяжемся с вами в самые кратчайшие сроки." //  response.data;
            //         showDialog(modal);
            //     }).catch(response => {
            //             document.querySelector('.form-modal__end_img').src = require('../images/icons/modal-error.svg');
            //             document.querySelector('.modal__end_title').innerText = "Произошла ошибка.
            // Попробуйте позже." //  response.data;
            //             showDialog(modal);
            //         }
            //
            //     );
            // } catch (e) {
            //     document.querySelector('.form-modal__end_img').src = require('../images/icons/modal-error.svg');
            //     document.querySelector('.modal__end_title').innerText = "Произошла ошибка.
            // Попробуйте позже." //  response.data;
            //     showDialog(modal);
            // }

        }


    });
});
